.hero-container {
  max-width: 100vw;
  height: calc(100vh - 1.5rem - 3rem);
  overflow: hidden;
  position: relative;
  background: #4e54c8;
  background: -webkit-linear-gradient(180deg, #8f94fb, #4e54c8);
  background: linear-gradient(180deg, #8f94fb, #4e54c8);
  padding: 5rem 20%;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Mulish", sans-serif;
}

.hero-text-container {
  max-width: 75vw;
}

.hero-container h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
}

.hero-container h1 span {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.hero-container h1 span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -2.5%;
  width: 105%;
  height: 1rem;
  background-color: #ff8c00;
  z-index: -1;
}

.hero-container p {
  margin: 1rem auto 2rem auto;
  font-size: 1.25rem;
  font-weight: 400;
  opacity: 0.8;
}

.get-started-btn {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4e54c8;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 100rem;
  background-color: #fff;
  transition: all 0.25s ease;
  border: 2px solid transparent;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.35);
}

.get-started-btn:hover {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
}

.center-logo {
  width: 20rem;
  margin-left: 2vmax;
  animation: zoom-in 0.5s 1;
  filter: drop-shadow(0px 15px 10px rgb(0 0 0 / 0.15));
}

@media screen and (max-width: 550px) {
  .hero-container {
    flex-direction: column-reverse;
    padding: 0;
  }
  .center-logo {
    margin-left: 0;
  }
  .hero-container h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 1400px) {
  .center-logo {
    width: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .hero-container {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    padding: 1rem;
  }
  .hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
  }
  .center-logo {
    width: 10rem;
    margin: 0 0 2rem 0;
  }
}

@keyframes zoom-in {
  from {
    /* transform: scale(0); */
    opacity: 0.5;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
