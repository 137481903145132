.cart-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}

.empty-cart {
  text-align: center;
}

/* cart items list styling */

.cart-items-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-item {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border-top: 0.05rem solid #999;
}

.cart-item .course-details {
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.cart-item:last-child {
  border-bottom: 0.05rem solid #999;
}

.left-aligned,
.right-aligned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.cart-item .card-img {
  width: 100px;
  border-radius: 0.25rem;
}

.course-price {
  font-size: 1.15rem;
}

.remove {
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  font-size: 1.15rem;
  text-align: right;
  transition: color 0.2s linear;
  color: #b7b7b7;
}

.remove:hover {
  color: #ff6666;
}

/* order summary styling */

.order-summary-container {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1rem #b7b7b780;
}

.order-summary-container > .sub-heading {
  text-align: center;
}

.order-summary-table {
  font-size: 1.05rem;
  border-collapse: collapse;
}

.order-summary-table td {
  padding: 0.5rem 1rem;
}

.summary-item:last-child {
  border-top: 0.1rem solid #999;
}

.summary-label.total {
  font-weight: 600;
}

.summary-amt {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 750px) {
  .cart-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cart-items-container,
  .order-summary-container,
  .order-summary-table {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .cart-item .course-details {
    width: 100%;
  }
}
