.contents-list-container {
  margin: 2rem 0;
}

.sub-heading {
  margin: 0.5rem 0;
}

.contents-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.content-list-item {
  margin-left: 2rem;
}

.content-list-item.sub-heading {
  margin-left: 1rem;
  font-weight: 600;
  font-size: 1.15rem;
}
.sub-list {
  margin: 1rem;
}

.list-content {
    font-size: 1.05rem;
}