.checkout-modal {
  width: 100%;
  height: inherit;
  /* position: absolute; */
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  z-index: 9999;
}

.checkout-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  gap: 2rem;
}

.checkout-window form {
  margin-left: 0;
}

@media screen and (max-width: 1000px) {
  .checkout-window {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .checkout-window {
    padding: 0 1rem;
  }
}
