.courses-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.course-card {
  width: 20%;
  min-width: 250px;
  max-width: 300px;
  text-align: center;
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 0 0 10px rgb(183, 183, 183);
  background-color: #fff;
  transition: transform 0.1s linear, box-shadow 0.1s linear;
  animation: fade-in-up 0.5s 1;
  cursor: pointer;
}

.course-card:hover {
  box-shadow: 1px 1px 10px rgb(223, 223, 223);
}

.image-container {
  max-width: 300px;
  max-height: 200px;
  overflow: hidden;
  transition: padding 0.1s linear;
}

.card-image {
  transform: scale(1.3);
  width: 100%;
}

.card-text-container {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  gap: 0.5rem;
}

.card-title {
  font-size: 1.25rem;
}
.course-details {
  text-align: justify;
  font-size: 0.875rem;
}

.new-price,
.old-price {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.course-author {
  font-weight: 600;
  font-style: italic;
}

.add-to-cart-btn,
.remove-from-cart-btn {
  align-self: center;
  margin: 1rem 0;
  width: fit-content;
  padding: 0.5rem 3rem;
  /* background-color: #ffa500; */
  border: none;
  outline: none;
  border-radius: 2px;
  color: rgb(245, 245, 245);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-family: "PT Sans";
  cursor: pointer;
  transition: opacity 0.1s ease;
}

.add-to-cart-btn {
  background-image: linear-gradient(#8bc34a, #4caf50);
}

.remove-from-cart-btn {
  background-image: linear-gradient(#ff6666, #ff6666);
}

.add-to-cart-btn:hover,
.remove-from-cart-btn:hover {
  opacity: 0.8;
}

.read-more {
  font-weight: 600;
  cursor: pointer;
}

.read-more:hover {
  opacity: 0.5;
}

.price {
  font-weight: 600;
}
.old-price {
  font-size: 0.875rem;
  opacity: 0.75;
  text-decoration: line-through;
  font-style: italic;
  font-weight: 400;
}
.new-price {
  font-weight: 600;
}

.add-to-cart-btn:disabled {
  opacity: 0.7;
  cursor: no-drop;
}

@keyframes fade-in-up {
  0% {
    opacity: 0.5;
    transform: translateY(1rem);
  }
  100% {
    opaicty: 1;
    transform: translateY(0);
  }
}
