.model-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  place-items: center;
  animation: fade-in 0.2s;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  max-width: 500px;
  margin: 2rem;
  font-family: "PT Sans";
  overflow: hidden;
  animation: grow 0.2s;
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-close-btn {
  align-self: flex-end;
  background-color: transparent;
  color: var(--color-dark-blue);
  font-size: 1.5rem;
  border: 0;
  cursor: pointer;
  transition: color 0.1s linear;
}

.modal-close-btn:hover {
  color: rgb(224, 0, 0);
}
