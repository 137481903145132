:root {
  --color-primary: #144576;
  --color-accent: #00aeef;
  --color-neutral-blue: #137392;
  --color-dark-blue: #0b243c;
  --color-neutral-light-blue: #f6fcff;
}

@font-face {
  font-family: Momcake;
  src: url("./assets/fonts/MomcakeThin-9Y6aZ.otf") format("opentype");
}

@font-face {
  font-family: MomcakeBold;
  font-weight: bold;
  src: url("./assets/fonts/MomcakeBold-WyonA.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--color-neutral-light-blue);
}

header {
  background-color: rgb(255, 255, 255);
  padding: 0.75rem 2rem;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 0 0 10px rgb(211, 211, 211);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9998;
  font-family: "PT sans";
  position: relative;
}

.logo-container {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.logo-img {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.logo-text {
  font-size: 2.25rem;
  font-family: MomcakeBold;
  color: var(--color-neutral-blue);
  letter-spacing: 0.05rem;
}

.accent {
  color: var(--color-accent);
}

.menu-toggle {
  display: none;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
}

.nav-link {
  text-decoration: none;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 1.15rem;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
}

.cart-btn {
  display: block;
  font-size: 1.25rem;
  color: var(--color-dark-blue);
  position: relative;
}

.cart-items-number {
  font-size: 0.75rem;
  display: block;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  display: grid;
  place-items: center;
  background-color: var(--color-accent);
  color: #fff;
}

.menu-toggle {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  color: var(--color-dark-blue);
}

.section-container {
  margin: 5rem 5%;
  font-family: "PT sans";
}

.section-heading {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--color-dark-blue);
  text-align: center;
  position: relative;
  font-family: "PT Sans";
}

.sub-heading {
  font-family: "PT Sans";
}

@media screen and (max-width: 650px) {
  .section-container {
    margin: 2rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  html {
    font-size: 14px;
  }
  nav {
    width: 70%;
    height: 100vh;
    background-color: var(--color-dark-blue);
    display: block;
    position: absolute;
    top: 4.5rem;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
    transform: translateX(-100%);
    transition: transform 0.2s linear;
  }
  .nav-container {
    display: none;
  }
  .menu-toggle {
    display: block;
  }
  .menu-open {
    transform: translateX(0);
  }
  .nav-link {
    color: #fff;
    font-size: 1.5rem;
  }
  .cart-btn {
    color: #fff;
  }
}

@media screen and (max-width: 350px) {
  html {
    font-size: 10px;
  }
}

/* scroll bar */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  z-index: -1;
}
::-webkit-scrollbar-track {
  background: none;
  z-index: -1;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(228, 228, 228, 0.5);
  height: 2px;
  z-index: -1;
}

* {
  scrollbar-width: thin;
}

/* scroll bar */
