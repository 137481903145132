.contact-info {
  margin: 0 5rem 2rem 5rem;
}

.contact {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
}

.or {
  text-transform: uppercase;
  margin: 2rem 0;
  color: rgba(0, 0, 0, 0.5);
}

.contact a {
  text-decoration: none;
  text-transform: lowercase;
  color: var(--color-accent);
}

.row-flex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
}

input,
textarea {
  width: 25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  font-family: "PT Sans";
  border: 0;
  border-bottom: 2px solid #000;
  background-color: transparent;
  outline: none;
}

input:focus,
textarea:focus {
  border-bottom-color: var(--color-accent);
}

textarea {
  height: 10rem;
}

.submit-form-btn {
  padding: 0.5rem 2rem;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: var(--color-primary);
  border-radius: 2px;
  cursor: pointer;
  border: 0;
  outline: none;
  box-shadow: 0 0 10px rgb(177, 177, 177);
  transition: transform 0.1s linear, box-shadow 0.1s linear;
}

.submit-form-btn:hover {
  box-shadow: 1px 1px 10px rgb(133, 133, 133);
}

@media screen and (max-width: 1080px) {
  .contact-info {
    margin: 0 0 2rem 0;
  }
  .row-flex {
    min-width: 90%;
    flex-direction: column;
    align-items: center;
  }
  form {
    margin-left: 0;
  }
}

@media screen and (max-width: 350px) {
  input,
  textarea {
    width: 100%;
  }
  .or {
    margin: 0.1rem 0;
    text-align: center;
  }
}
